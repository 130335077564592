<template>
  <section id="writeIn" class="content" v-if="categories.length">
    <h4>
      Can't find your nominee?
      <p v-show="writeIns === 0" class="subtitle is-6">
        Click the button below to add a new record to our database.
      </p>
    </h4>
    <p class="is-italic" v-show="writeIns > 0">
      Write-ins are manually processed by our team.
      All write-ins are valid votes and will be counted.
      <br>
      It may take up to 4 business days for your write-in to
      appear to others. Scroll up to continue voting!
    </p>
    <div class="columns is-mobile" v-for="id in writeIns" :key="id">
      <div class="field is-horizontal column is-6">
        <div class="field-label is-normal">
          <label :for="`write-in-name-${id}`" class="label">Name</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control">
              <input
                type="text"
                :id="`write-in-name-${id}`"
                :name="`write-in-name-${id}`"
                @blur="$store.dispatch('updateVoteCount')"
                class="input vote-field"
                placeholder="Your Nominee's Name"
              >
            </p>
          </div>
        </div>
      </div>
      <div class="field is-horizontal column is-6">
        <div class="field-label is-normal is-hidden-tablet-only">
          <label :for="`write-in-category-${id}`" class="label">
            Category
          </label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control">
              <div class="select">
                <select :name="`write-in-category-${id}`">
                  <option
                    v-for="(category, index) in categories"
                    :key="index"
                    :value="slugify(category)"
                    v-html="category"
                  />
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a
      class="button is-dark is-outlined"
      @click="newWriteIn"
      v-show="writeIns !== writeInLimit"
    >
      <span class="icon is-small">
        <fa icon="plus" />
      </span>
      <span>Add Write-In</span>
    </a>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import slug from '@/lib/slug';

export default {
  data() {
    return {
      writeIns: 0,
    };
  },
  computed: {
    ...mapGetters({
      categoriesGetter: 'categories',
    }),
    writeInLimit() {
      return this.$store.state.write_in_limit;
    },
    categories() {
      const allCategories = [];
      Object.values(this.categoriesGetter).forEach((key) => {
        key.forEach((category) => {
          allCategories.push(category);
        });
      });
      return allCategories.sort();
    },
  },
  methods: {
    newWriteIn() {
      this.writeIns += 1;
    },
    slugify(string) {
      return slug(string);
    },
  },
};
</script>
